<template>
  <div class="flex flex-row">
    <div class="flex items-center justify-center w-full">
      <BaseCard class="w-full">
        <!-- <div class="h-full font-bold text-2xl text-gray-800 justify-center items-center flex flex-col">
          <div>
            Die FIT STAR Member Area ist
          </div>
          <div>
            momentan nicht verfügbar
          </div>
        </div> -->
        <div class="md:mt-24 xl:mt-32">
          <div class="font-bold text-center text-2xl">Wir freuen uns, euch wieder in unseren Clubs begrüßen zu dürfen.</div>
          <div class="text-center mt-6 text-xl">Der Self-Service zur Beantragung einer Ruhezeit aufgrund der Corona Einschränkungen wurde wieder deaktiviert.</div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import BaseCard from './components/BaseCard.vue';

export default {
  data() {
    return {
      loginData: {
        username: null,
        password: null,
      },
    };
  },
  components: {
    BaseCard,
  },
  created() {
    // this.$store.dispatch('initAuth');
  },
  computed: {
    hasFloatingWidth() {
      return window.innerWidth < 1024;
    },
    authToken() {
      return this.$store.state.authentication.authToken;
    },
    authError() {
      return !!this.$store.state.authentication.authError;
    },
    redirect() {
      const { query } = this.$route;
      if (query && query.redirect) {
        return query.redirect;
      }
      return null;
    },
  },
  watch: {
    authToken: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (typeof this.redirect === 'string') {
            this.$router.push({ name: this.redirect })
              // Suppress error of redirecting more than once.
              // TODO: Build routes in a way to only redirect max. once.
              .catch(() => {});
          } else {
            // this.$router.push({ name: 'timestop' });
            this.$router.push({ name: 'compensation' });
          }
        }
      },
    },
  },
  methods: {
    toRegister() {
      this.$router.push({ path: 'register' });
    },
    login() {
      this.$store.dispatch('login', this.loginData);
    },
    recoverPassword() {
      this.$router.push({ path: 'new-password' });
    },
  },
};
</script>

<style scoped>
.label-postion {
  position: relative;
  top: 25px;
  left: 12%;
}

@media (min-width: 1024px) {
  .height-desktop {
    min-height: 60vh;
  }
}

.mb-override {
  margin-bottom: 4px !important;
}
</style>
